import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Main } from './pages/Main';
import { ChargeCredit } from './pages/ChargeCredit';

function App() {
    return (        
        <Router>
            <Routes>
                <Route path="/" element={<Main />} />
                <Route path="/charge-credit" element={<ChargeCredit />} />
            </Routes>
        </Router>
    );
}

export default App;