export const stringDoms = {
    imageFrameMore: (
        <span>
            {'(이미지에는 '}
            <span style={{ textDecoration: 'underline' }}>한 명의 사람</span>
            {'만 포함되어야 하며, 파일 크기는 '}
            <span style={{ textDecoration: 'underline' }}>2MB 이하</span>
            {'여야 합니다.)'}
        </span>
    ),
};
