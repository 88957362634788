import { FC, useState, useEffect } from 'react';
import { HamburgerIcon } from '../components/HamburgerIcon';

import { strings } from '../strings';

import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, signInWithPopup, onAuthStateChanged, User, signOut } from 'firebase/auth';
import { getFirestore, doc, getDoc, setDoc, onSnapshot, addDoc, collection } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import { loadStripe } from '@stripe/stripe-js';
import { useLocation } from 'react-router-dom';

const firebaseConfig = {
    apiKey: "AIzaSyAYU8h9Syjuh7A0K0MHGIQIBwFQaewxbjA",
    authDomain: "unluckyvicky-f54f8.firebaseapp.com",
    projectId: "unluckyvicky-f54f8",
    storageBucket: "unluckyvicky-f54f8.appspot.com",
    messagingSenderId: "534090108550",
    appId: "1:534090108550:web:3b29fda618cd5dc2cd0b07",
    measurementId: "G-0SW7KY4BXN"
};

const stripePublicKey = process.env.REACT_APP_STRIPE_PUBLIC_KEY || '';
const app = initializeApp(firebaseConfig);

interface CreateCheckoutSessionResult {
    sessionId: string;
}

export const ChargeCredit: FC = () => {

    const [user, setUser] = useState<User | null>(null);
    const [credits, setCredits] = useState<number>(0);
    const [isCheckOut, setIsCheckOut] = useState<boolean | null>(false);
    const [selectedPrice, setSelectedPrice] = useState<number>(0);
    const auth = getAuth();
    const [drawerOpened, setDrawerOpened] = useState<boolean | null>(null);

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const status = queryParams.get('status');

    const choosePrice = (price: number) => {
        if (selectedPrice === price) {
            setSelectedPrice(0);
        }
        else {
            setSelectedPrice(price);           
        }
    }

    const charge = async (creditAmount: number) => {
        setIsCheckOut(true);
        let priceId = '';

        if (creditAmount === 3000) {
            priceId = 'price_1Q7sY1GPPxYON6qr5iQkpmwk';
        } else if (creditAmount === 5500) {
            priceId = 'price_1Q7sYYGPPxYON6qrULZWVNr9';
        } else if (creditAmount === 15000) {
            priceId = 'price_1Q7sZOGPPxYON6qrpyoNM7MD';
        } else {
            throw new Error('Invalid credit amount');
        }

        const auth = getAuth(app);
        const userId = auth.currentUser?.uid;
        if (!userId) throw new Error('User is not authenticated');

        const db = getFirestore(app);
        const checkoutSessionRef = collection(
            db,
            'customers',
            userId,
            'checkout_sessions'
        );

        const docRef = await addDoc(checkoutSessionRef, {
            mode: 'payment',
            price: priceId,
            success_url: window.location.origin,
            cancel_url: window.location.origin,
            metadata: {
            creditAmount: creditAmount.toString(),
            },
        });

        // Checkout 세션 생성 완료를 기다립니다.
        const unsubscribe = onSnapshot(docRef, async (snap) => {
            const data = snap.data();
            console.log('Snapshot data:', data);
        
            const { error, url } = data as {
              error?: { message: string };
              url?: string;
            };
        
            if (error) {
              console.error('An error occurred: ', error.message);
              unsubscribe();
              setIsCheckOut(false);
            }
        
            if (url) {
              console.log('Stripe Checkout URL:', url);
              unsubscribe();
        
              // URL로 직접 리디렉션
              window.location.href = url;
              setIsCheckOut(false);
            }
          });
    }

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
            setUser(currentUser);
            if (currentUser) {
                const db = getFirestore(app);
                const userRef = doc(db, "customers", currentUser.uid);

                const userDoc = await getDoc(userRef);
                if (!userDoc.exists()) {
                    // 사용자 문서 생성
                    await setDoc(userRef, { credits: 0 });
                    console.log(`사용자 문서를 생성했습니다: ${currentUser.uid}`);
                }

                const unsubscribeCredits = onSnapshot(userRef, (doc) => {
                    if (doc.exists()) {
                        const userData = doc.data();
                        setCredits(userData.credits || 0);
                    }
                });
                return () => unsubscribeCredits();
            } else {
                setCredits(0);
            }
        });
        
        return () => unsubscribe();
    }, [auth]);

    const signout = () => {
        signOut(auth)
        .then(() => {
            console.log('User signed out successfully');
            window.location.href = '/';
        })
        .catch((error) => {
            console.error('Error signing out:', error);
        });
    };

    return (
        <div
        style={{
            position: 'relative',
            margin: 'auto',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: 500,
            maxWidth: '100%',
            height: '100%',
            overflowX: 'hidden',
            minHeight: '100vh',
        }}>
        <header
            className="topnav"
            style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
            }}>
            <span
                onClick={() => {
                    window.location.href = '/';
                }}
                style={{
                    marginLeft: 20,
                    color: 'var(--white)',
                    fontWeight: 800,
                    fontSize: 'var(--f-large)',
                }}>
                {strings.appName}
            </span>
            {user ? (
                <div
                    style= {{
                        display: 'flex',
                        alignItems: 'center'
                    }}
                >
                    <div
                        style= {{
                            color: 'white',
                            borderRadius: '4px',
                            border: '1px solid white',
                            padding: '4px 10px',
                        }}
                    >
                        {credits} Credits
                    </div>
                    <HamburgerIcon onClick={() => setDrawerOpened((v) => !v)} />
                </div>
            ) : (
                <p id="signinButton"
                    style= {{
                    marginRight: '20px',
                    color: 'var(--white)',
                    textDecoration: 'underline',
                    cursor: 'pointer',
                    }}
                >
                    {strings.toLoginSignup}
                </p>
            )}
        </header>    
        
            <div
                style={{
                    width: '100%',
                    minHeight: 'calc(100vh - 120px)',
                    maxHeight: '100%',
                    overflowY: 'scroll',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
            >
                {
                    isCheckOut ? 
                    <div>
                        결제창으로 이동합니다.
                    </div> 
                    :
                    <div
                        style={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                    >
                        <button 
                            onClick={()=>choosePrice(3000)}
                            style={{
                                width: 'calc(100% - 140px)',
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                padding: '8px 14px',
                                border: (selectedPrice === 3000 ? '2px solid var(--primary)' : '2px solid #cccccc'),
                                backgroundColor: (selectedPrice === 3000 ? '#D3EDEB' : 'var(--white)'),
                                borderRadius: '8px',
                                margin: '10px'
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'left',
                                    textAlign: 'left'
                                }}
                            >
                                <span style={{color: 'var(--black)', fontWeight: '800', fontSize: '16px', marginTop:'2px'}}>3000 Credits</span>
                                <span style={{color:'#555', marginTop:'6px'}}>3000원</span>
                            </div>
                            {selectedPrice === 3000 ? 
                                <img 
                                    src={`${window.origin}/assets/check.svg`}
                                    style={{
                                        width: '20px',
                                        height: '20px'
                                    }}
                                />
                                :
                                null                        
                            }
                        </button>
                        <button 
                            onClick={()=>choosePrice(5500)}
                            style={{
                                width: 'calc(100% - 140px)',
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                padding: '8px 14px',
                                border: (selectedPrice === 5500 ? '2px solid var(--primary)' : '2px solid #cccccc'),
                                backgroundColor: (selectedPrice === 5500 ? '#D3EDEB' : 'var(--white)'),
                                borderRadius: '8px',
                                margin: '10px'
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'left',
                                    textAlign: 'left'
                                }}
                            >
                                <span style={{color: 'var(--black)', fontWeight: '800', fontSize: '16px', marginTop:'2px'}}>5000(+500) Credits</span>
                                <span style={{color:'#555', marginTop:'6px'}}>5000원</span>
                            </div>
                            {selectedPrice === 5500 ? 
                                <img 
                                src={`${window.origin}/assets/check.svg`}
                                    style={{
                                        width: '20px',
                                        height: '20px'
                                    }}
                                />
                                :
                                null                        
                            }
                        </button>
                        <button 
                            onClick={()=>choosePrice(15000)}
                            style={{
                                width: 'calc(100% - 140px)',
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                padding: '8px 14px',
                                border: (selectedPrice === 15000 ? '2px solid var(--primary)' : '2px solid #cccccc'),
                                backgroundColor: (selectedPrice === 15000 ? '#D3EDEB' : 'var(--white)'),
                                borderRadius: '8px',
                                margin: '10px'
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'left',
                                    textAlign: 'left'
                                }}
                            >
                                <span style={{color: 'var(--black)', fontWeight: '800', fontSize: '16px', marginTop:'2px'}}>12000(+3000) Credits</span>
                                <span style={{color:'#555', marginTop:'6px'}}>12000원</span>
                            </div>
                            {selectedPrice === 15000 ? 
                                <img 
                                    src={`${window.origin}/assets/check.svg`}
                                    style={{
                                        width: '20px',
                                        height: '20px'
                                    }}
                                />
                                :
                                null                        
                            }
                        </button>
                        <button 
                            className='charge-button'
                            onClick={() => charge(selectedPrice)}
                            disabled={(selectedPrice === 0)}
                            style={{
                                width: 'calc(100% - 140px)',
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'center',
                                padding: '6px 14px',
                                border: '0px',
                                borderRadius: '30px',
                                margin: '20px 4px 0'
                            }}
                        >
                            결제하기
                        </button>
                    </div>
                }
            </div>

            <div
                onClick={() => setDrawerOpened(false)}
                className={
                    drawerOpened === true
                        ? 'drawer-open'
                        : drawerOpened === false
                        ? 'drawer-closed'
                        : undefined
                }
                style={{
                    position: 'absolute',
                    top: 50,
                    left: 0,
                    width: '200%',
                    height: 'calc(100% - 50px)',
                    backgroundColor: '#00000033',
                    opacity: 0,
                    transform: 'translateX(0)',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    pointerEvents: 'none',
                }}>
                <div
                    style={{
                        height: '100%',
                        padding: 12,
                        minWidth: 132,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        backgroundColor: 'var(--white)',
                    }}>
                    <span 
                        onClick={() => {
                            window.location.href = '/charge-credit';
                        }}
                        style={{ padding: 12 }}
                    >
                        {strings.charge}
                    </span>
                    <span style={{ padding: 12 }} onClick={signout}>
                        {strings.signout}
                    </span>
                </div>
            </div>
        </div>
    );
};