const postHeaders: HeadersInit = {
    'x-api-key': 'nhbwDZCtlSZj-9PP0hrRVlNz9uDyoR8f8vvjhH80p_0',
};
const getHeaders: HeadersInit = {
    'Content-Type': 'application/json',
    'x-api-key': 'nhbwDZCtlSZj-9PP0hrRVlNz9uDyoR8f8vvjhH80p_0',
};

export const fetchCreateTalkingPhoto = async (
    image: File,
    audio?: File,
    voiceId?: string,
    text?: string
) => {
    try {
        const formData = new FormData();
        formData.append('image_file', image);
        if (audio) {
            formData.append('audio_file', audio);
        }
        if (voiceId) {
            formData.append('voice_id', voiceId);
        }
        if (text) {
            formData.append('text', text);
        }
        const result = await fetch('https://api.ejelai.com/v1/talkingphotos', {
            method: 'POST',
            headers: postHeaders,
            body: formData,
        }).then((v) => v.json());
        return result.video_id as string;
    } catch (e) {
        console.log(e);
        return false;
    }
};

export const fetchVideoStatus = async (id: string) => {
    try {
        const result = await fetch(
            `https://api.ejelai.com/v1/talkingphotos/${id}/status`,
            {
                method: 'GET',
                headers: getHeaders,
            }
        ).then((v) => v.json());
        return result.status as 'READY' | 'GENERATING' | 'SUCCESS' | 'FAIL';
    } catch (e) {
        console.log(e);
        return false;
    }
};

export const fetchVideoUrl = async (id: string) => {
    try {
        const result = await fetch(
            `https://api.ejelai.com/v1/talkingphotos/${id}/video`,
            {
                method: 'GET',
                headers: getHeaders,
            }
        ).then((v) => v.json());
        return result.video_url as string;
    } catch (e) {
        console.log(e);
        return false;
    }
};
