import { FC, HTMLAttributes } from 'react';

const Step: FC<{ step: number; active: boolean }> = ({ step, active }) => (
    <div className={`step ${active ? 'active' : 'inactive'}`}>
        <span>{step}</span>
    </div>
);

const Dot: FC<{ active: boolean }> = ({ active }) => (
    <div className={`dot ${active ? 'active' : 'inactive'}`} />
);

const ARRAY_4 = Array.from(Array(4).keys());

export const StepIndicator: FC<
    HTMLAttributes<HTMLDivElement> & { currentStep: number }
> = ({ currentStep, ...props }) => (
    <div
        {...props}
        style={{
            ...props.style,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
        }}>
        <Step step={1} active={currentStep >= 0} />
        {ARRAY_4.map((_, ind) => (
            <Dot key={`dots_a_${ind}`} active={currentStep >= 1} />
        ))}
        <Step step={2} active={currentStep >= 1} />
        {ARRAY_4.map((_, ind) => (
            <Dot key={`dots_b_${ind}`} active={currentStep >= 2} />
        ))}
        <Step step={3} active={currentStep >= 2} />
    </div>
);
