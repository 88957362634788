import { FC, HTMLAttributes } from 'react';
import { strings } from '../strings';
import { stringDoms } from '../stringDoms';

export const ImageFrame: FC<
    HTMLAttributes<HTMLDivElement> & {
        image: null | string;
        idAwaited: string | null;
        videoUrl: string | null;
    }
> = ({ image, idAwaited, videoUrl, ...props }) => (
    <div
        className="image-frame"
        {...props}
        style={{
            ...props.style,
            position: 'relative',
            border: image ? '0px' : '2px dashed #cacaca',
            color: '#505050',
            padding: 'var(--margin)',
        }}>
        <span style={{ fontWeight: 800, fontSize: 'var(--f-medium)' }}>
            {strings.imageFrameMain}
        </span>
        <br />
        <span
            style={{
                fontWeight: '400',
                textAlign: 'center',
            }}>
            {stringDoms.imageFrameMore}
        </span>
        {image ? (
            <img
                alt={'what you uploaded'}
                src={image}
                style={{
                    width: '100%',
                    height: '100%',
                    position: 'absolute',
                    left: 0,
                    top: 0,
                    objectFit: 'cover',
                }}
            />
        ) : null}
        {idAwaited ? (
            <div
                style={{
                    width: '100%',
                    height: '100%',
                    position: 'absolute',
                    left: 0,
                    top: 0,
                    objectFit: 'cover',
                    backgroundColor: 'var(--white)',
                    opacity: 0.5,
                }}
            />
        ) : null}
        {videoUrl ? (
            <video
                src={videoUrl}
                style={{
                    width: '100%',
                    height: '100%',
                    position: 'absolute',
                    left: 0,
                    top: 0,
                }}
                controls
            />
        ) : null}
    </div>
);
