import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { HamburgerIcon } from '../components/HamburgerIcon';
import { ImageFrame } from '../components/ImageFrame';
import { StepIndicator } from '../components/StepIndicator';
import { strings } from '../strings';
import {
    fetchCreateTalkingPhoto,
    fetchVideoStatus,
    fetchVideoUrl,
} from '../lib/api';
import { audios } from '../audios';
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, signInWithPopup, onAuthStateChanged, User, signOut } from 'firebase/auth';
import { getFirestore, doc, getDoc, setDoc, runTransaction } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyAYU8h9Syjuh7A0K0MHGIQIBwFQaewxbjA",
    authDomain: "unluckyvicky-f54f8.firebaseapp.com",
    projectId: "unluckyvicky-f54f8",
    storageBucket: "unluckyvicky-f54f8.appspot.com",
    messagingSenderId: "534090108550",
    appId: "1:534090108550:web:3b29fda618cd5dc2cd0b07",
    measurementId: "G-0SW7KY4BXN"
};
  
const app = initializeApp(firebaseConfig);

export const Main: FC = () => {
    const [user, setUser] = useState<User | null>(null);
    const [credits, setCredits] = useState<number>(0);
    const auth = getAuth();
    const provider = new GoogleAuthProvider();

    const [imageFile, setImageFile] = useState<File | null>(null);
    const [audioFile, setAudioFile] = useState<File | null>(null);
    const [idAwaited, setIdAwaited] = useState<string | null>(null);
    const [videoUrl, setVideoUrl] = useState<string | null>(null);
    const [drawerOpened, setDrawerOpened] = useState<boolean | null>(null);
    const [statusText, setStatusText] = useState<string | null>(null);
    const [sent, setSent] = useState(false);
    const image = useMemo(
        () => (imageFile ? URL.createObjectURL(imageFile) : null),
        [imageFile]
    );
    const [audioSelected, setAudioSelected] = useState<number | undefined>(
        undefined
    );
    const [audioInputKey, setAudioInputKey] = useState(0);
    const [currentAudio, setCurrentAudio] = useState<HTMLAudioElement | null>(null);

    function signInWithGoogle() {
        signInWithPopup(auth, provider)
            .then((result) => {
                const credential = GoogleAuthProvider.credentialFromResult(result);
                const token = credential!.accessToken;
                document.cookie = `userJWT=${token}; path=/; secure; httponly;`;
                window.location.href = '/';
                const user = result.user;
            }).catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                const email = error.customData.email;
                const credential = GoogleAuthProvider.credentialFromError(error);
            });
    }

    useEffect(() => {
        document.getElementById('signinButton')!.addEventListener('click', signInWithGoogle);
    }, []);
    
    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
            setUser(currentUser);
            if (currentUser) {
                const db = getFirestore(app);
                const userRef = doc(db, "customers", currentUser.uid);
                const userDoc = await getDoc(userRef);
                if (userDoc.exists()) {
                  const userData = userDoc.data();
                  setCredits(userData.credits || 0);
                } else {
                await setDoc(userRef, { credits: 0 });
                  setCredits(0);
                }
            } else {
                setCredits(0);
            }
        });
        
        return () => unsubscribe();
    }, [auth]);

    const spendCredits = async (amount: number) => {
        if (!user) {
            alert("크레딧을 사용하려면 로그인해야 합니다.");
            return;
        }
    
        if (credits < amount) {
            alert("크레딧이 충분하지 않습니다!");
            return;
        }
    
        const db = getFirestore(app);
        const userRef = doc(db, "customers", user.uid);
    
        try {
            await runTransaction(db, async (transaction) => {
                const userDoc = await transaction.get(userRef);
                if (!userDoc.exists()) {
                    throw "사용자 문서가 존재하지 않습니다!";
                }
    
                const currentCredits = userDoc.data()?.credits || 0;
    
                if (currentCredits < amount) {
                    throw "크레딧이 충분하지 않습니다!";
                }
    
                transaction.update(userRef, { credits: currentCredits - amount });
            });

            setCredits(credits - amount); // 로컬 상태 업데이트
        } catch (error: any) {
            console.error("트랜잭션 실패: ", error);
            if (error === "크레딧이 충분하지 않습니다!") {
                alert("크레딧이 충분하지 않습니다!");
            } else {
                alert("크레딧 사용에 실패했습니다.");
            }
        }
    };

    useEffect(() => {
        if (audioFile) {
            setAudioSelected(undefined);
        }
    }, [audioFile]);

    useEffect(() => {
        if (audioSelected !== undefined) {
            playSound(`${window.origin}/audio/${
                audios[(audioSelected as number) - 1].filename
            }`);
            setAudioInputKey((v) => v + 1);
            setAudioFile(null);
        }
    }, [audioSelected]);

    const playSound = (audioFile: string) => {
        if (currentAudio) {
            currentAudio.pause();
            currentAudio.currentTime = 0;
        }
    
        const audio = new Audio(audioFile);
        audio.play();
    
        setCurrentAudio(audio);
    };

    const stopCurrentAudio = () => {
        if (currentAudio) {
            currentAudio.pause();
            currentAudio.currentTime = 0;
            setCurrentAudio(null);
        }
    };

    const handleAudioSelection = (index: number) => {
        if (audioSelected === index + 1) {
            setAudioSelected(undefined);
            stopCurrentAudio();
        } else {
            setAudioSelected(index + 1);
        }
    };

    const sendCreateRequest = async () => {
        if (!user) {
            return alert('not authenticated');
        }
        if (!imageFile) {
            return alert('no image');
        }
        if (!audioFile && !audioSelected) {
            return alert('no audio');
        }
        setSent(true);
        stopCurrentAudio();
        const audio =
            audioFile ??
            ((await fetch(
                `${window.origin}/audio/${
                    audios[(audioSelected as number) - 1].filename
                }`
            )
                .then((v) => v.blob())
                .then((v) => new File([v], 'audio.mp3'))) as File);
        const result = await fetchCreateTalkingPhoto(imageFile, audio);
        if (result) {
            spendCredits(audios[(audioSelected as number) - 1].time * 100);
            setVideoUrl(null);
            setIdAwaited(result);
        } else {
            console.log("c");
            alert('unknown error!');
        }
    };

    useEffect(() => {
        setVideoUrl(null);
    }, []);

    const getVideoUrl = useCallback(async () => {
        if (!idAwaited) return;
        const result = await fetchVideoUrl(idAwaited);
        if (result) {
            setVideoUrl(result);
        } else {
            console.log("b");
            alert('unknown error!');
        }
    }, [idAwaited]);

    const checkStatus = useCallback(async () => {
        if (!idAwaited) return;
        const result = await fetchVideoStatus(idAwaited);
        if (!result) {
            console.log("a");
            return alert('unknown error!');
        }
        setStatusText(result);
        if (result === 'SUCCESS') {
            getVideoUrl();
        } else {
            setTimeout(checkStatus, 5000);
        }
    }, [getVideoUrl, idAwaited]);

    useEffect(() => {
        if (idAwaited && !videoUrl) {
            checkStatus();
        }
    }, [checkStatus, idAwaited, videoUrl]);

    const currentStep = useMemo(
        () =>
            videoUrl
                ? 4
                : sent
                ? 3
                : audioFile || audioSelected
                ? 2
                : image
                ? 1
                : 0,
        [videoUrl, sent, audioFile, audioSelected, image]
    );

    const buttonText = useMemo(() => {
        if (videoUrl) return strings.download;
        switch (statusText) {
            case 'READY':
                return strings.ready;
            case 'GENERATING':
            case 'SUCCESS':
                return strings.generating;
            case 'FAIL':
                return strings.failed;
            default:
                return strings.go;
        }
    }, [statusText, videoUrl]);

    const refreshPage = () => {
        window.location.reload();
    };

    const signout = () => {
        signOut(auth)
        .then(() => {
            console.log('User signed out successfully');
            window.location.href = '/';
        })
        .catch((error) => {
            console.error('Error signing out:', error);
        });
    };

    return (
        <div
            style={{
                position: 'relative',
                margin: 'auto',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: 500,
                maxWidth: '100%',
                height: '100%',
                overflowX: 'hidden',
                minHeight: '100vh',
            }}>
            <header
                className="topnav"
                style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}>
                <span
                    onClick={() => {
                        window.location.href = '/';
                    }}
                    style={{
                        marginLeft: 20,
                        color: 'var(--white)',
                        fontWeight: 800,
                        fontSize: 'var(--f-large)',
                    }}>
                    {strings.appName}
                </span>
                {user ? (
                    <div
                        style= {{
                            display: 'flex',
                            alignItems: 'center'
                        }}
                    >
                        <div
                            style= {{
                                color: 'white',
                                borderRadius: '4px',
                                border: '1px solid white',
                                padding: '4px 10px',
                            }}
                        >
                           {credits} Credits
                        </div>
                        <HamburgerIcon onClick={() => setDrawerOpened((v) => !v)} />
                    </div>
                ) : (
                    <p id="signinButton"
                        style= {{
                        marginRight: '20px',
                        color: 'white',
                        textDecoration: 'underline',
                        cursor: 'pointer',
                      }}
                    >
                        {strings.toLoginSignup}
                    </p>
                )}
            </header>
            <div
                style={{
                    width: '100%',
                    maxHeight: '100%',
                    overflowY: 'scroll',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    paddingBottom: 20,
                }}>
                <StepIndicator
                    style={{ marginTop: 35 }}
                    currentStep={currentStep}
                />
                <span
                    style={{
                        margin: '9px 0 22px',
                        fontSize: 'var(--f-smaller)',
                        textAlign: 'center',
                        fontWeight: 800,
                    }}>
                    {strings.steps[currentStep]}
                </span>
                <input
                    disabled={currentStep === 3}
                    id="image-input"
                    type="file"
                    style={{ display: 'none' }}
                    accept="image/*"
                    onChange={(e) => {
                        const file = e.target.files?.[0];
                        if (file) {
                            if (file.size > 2e6) {
                                return alert('image too big.');
                            }
                            setImageFile(file);
                        }
                    }}
                />
                <ImageFrame
                    image={image}
                    style={{
                        width: 'calc(100% - 2 * var(--margin))',
                        height: 'calc(min(500px, 100vw) - 2 * var(--margin))',
                    }}
                    onClick={() => {
                        if (!user) {
                            signInWithGoogle();
                            return;
                        }
                        if (sent) {
                            return;
                        }
                        document.getElementById('image-input')?.click();
                    }}
                    idAwaited={idAwaited}
                    videoUrl={videoUrl}
                />
                <input
                    disabled={currentStep === 3}
                    key={`audioinput_${audioInputKey}`}
                    id="audio-input"
                    type="file"
                    accept="audio/*"
                    style={{ display: 'none' }}
                    onChange={(e) => {
                        const file = e.target.files?.[0];
                        if (file) {
                            setAudioFile(file);
                        }
                    }}
                />
                {currentStep >= 1 && currentStep < 3 ? (
                    <>
                        <div className="audioList">
                            {audios.map((v, ind) => (
                                <div
                                    key={`audioItem_${ind}`}
                                    className={`audioItem ${
                                        audioSelected === (ind + 1) ? 'selected' : ''
                                    }`}
                                    onClick={() => handleAudioSelection(ind)}>
                                    <div className='info'>
                                        <span className="index">{ind + 1}</span>
                                        <span className="title">{v.title}</span>
                                    </div>
                                    <span className='credit'>{v.time * 100} Credits</span>
                                </div>
                            ))}
                            {/* <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    height: 36,
                                    borderRadius: 10,
                                    backgroundColor: 'var(--primary)',
                                    justifyContent: 'center',
                                    color: 'var(--white)',
                                    fontSize: 'var(--f-small)',
                                    fontWeight: '700',
                                    marginTop: 8,
                                }}
                                onClick={() => {
                                    document
                                        .getElementById('audio-input')
                                        ?.click();
                                }}>
                                <span>
                                    {audioFile
                                        ? audioFile.name
                                        : strings.addAudio}
                                </span>
                            </div> */}
                        </div>
                    </>
                ) : null}
                {videoUrl ? (
                    <a
                        href={videoUrl}
                        download={videoUrl}
                        target="_blank"
                        style={{
                            marginTop: 18,
                            width: 'calc(100% - 180px)',
                            textDecoration: 'none',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                        className="rigid-button"
                        rel="noreferrer">
                        {buttonText}
                    </a>
                ) : (
                    <button
                        style={{
                            marginTop: 18,
                            width: 'calc(100% - 180px)',
                        }}
                        className="rigid-button"
                        disabled={audioSelected ? 
                                (credits - audios[(audioSelected as number) - 1].time * 100 < 0 ? 
                                true
                                :
                                currentStep !== 2 && !videoUrl)
                                :
                                currentStep !== 2 && !videoUrl
                            }
                        onClick={
                            videoUrl
                                ? () => window.open(videoUrl)
                                : sendCreateRequest
                        }>
                        {audioSelected ? ((credits - audios[(audioSelected as number) - 1].time * 100 < 0) ? '크레딧이 부족합니다' : buttonText) : buttonText}
                    </button>
                )}
                {currentStep === 3 ? (
                    audioSelected ?
                    <a
                        style={{
                            marginTop: 10,
                            width: 'calc(100% - 180px)',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >{strings.time_indicate_begin + String(audios[(audioSelected as number) - 1].time * 6) + strings.time_indicate_end}
                    </a>
                    :
                    <a
                        style={{
                            marginTop: 10,
                            width: 'calc(100% - 180px)',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >{strings.time_indicate_begin + '90' + strings.time_indicate_end}
                    </a>                    
                )
                :
                null       
                }
                {currentStep === 4 ? (
                    <button
                        style={{
                            marginTop: 10,
                            width: 'calc(100% - 180px)',
                        }}
                        className="rigid-button"
                        onClick={refreshPage}
                    >{strings.restart}
                    </button>
                )
                :
                null       
                }
            </div>
            <div
                onClick={() => setDrawerOpened(false)}
                className={
                    drawerOpened === true
                        ? 'drawer-open'
                        : drawerOpened === false
                        ? 'drawer-closed'
                        : undefined
                }
                style={{
                    position: 'absolute',
                    top: 50,
                    left: 0,
                    width: '200%',
                    height: 'calc(100% - 50px)',
                    backgroundColor: '#00000033',
                    opacity: 0,
                    transform: 'translateX(0)',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    pointerEvents: 'none',
                }}>
                <div
                    style={{
                        height: '100%',
                        padding: 12,
                        minWidth: 132,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        backgroundColor: 'var(--white)',
                    }}>
                    <span 
                        onClick={() => {
                            window.location.href = '/charge-credit';
                        }}      
                        style={{ padding: 12 }}
                    >
                        {strings.charge}
                    </span>
                    <span style={{ padding: 12 }} onClick={signout}>
                        {strings.signout}
                    </span>
                </div>
            </div>
        </div>
    );
};
