export const audios: { title: string; filename: string, time: number}[] = [
    {
        title: '탕후루',
        filename: 'Tanghulu.mp3',
        time: 9
    },
    {
        title: '암파인갠차나',
        filename: 'imfine.mp3',
        time: 7
    },
    {
        title: '개짖는 소리 좀 안나게 해라',
        filename: 'shouting.mp3',
        time: 7
    },
    {
        title: '펜파인애플애플펜(PPAP)',
        filename: 'ppap.mp3',
        time: 14
    },
    {
        title: '치즈버거',
        filename: 'cheeseburger.mp3',
        time: 11
    },
    {
        title: '콩나물',
        filename: 'bean_sprout.mp3',
        time: 11
    },
    {
        title: 'GODS',
        filename: 'GODS.mp3',
        time: 15
    },
    {
        title: '생일 축하',
        filename: 'happybirthday.mp3',
        time: 12
    },
    {
        title: '생일 축하(남성)',
        filename: 'happybirthday_man.mp3',
        time: 9
    },
    {
        title: '생일 축하(여자 아이 목소리)',
        filename: 'happybirthday_girl.mp3',
        time: 15
    },
    {
        title: '생일 축하 노래(유머)',
        filename: 'happybirthday_humor.mp3',
        time: 10
    },
    {
        title: '도널드 덕 목소리',
        filename: 'donald_duck.mp3',
        time: 7
    },
    {
        title: '강아지 소리',
        filename: 'dog_sound.mp3',
        time: 6
    },
    {
        title: '늑대 울음소리',
        filename: 'wolf_sound.mp3',
        time: 6
    },
    {
        title: '닭 울음소리',
        filename: 'chicken_sound.mp3',
        time: 11
    },
    {
        title: '돼지 울음소리',
        filename: 'pig_sound.mp3',
        time: 4
    },
    {
        title: '말 울음소리',
        filename: 'horse_sound.mp3',
        time: 4
    },
    {
        title: '소 울음소리',
        filename: 'cow_sound.mp3',
        time: 4
    },
    {
        title: '아기상어(아빠)',
        filename: 'babyshark_father.mp3',
        time: 8
    },
    {
        title: '아기상어(엄마)',
        filename: 'babyshark_mother.mp3',
        time: 8
    },
    {
        title: '아기상어(아이)',
        filename: 'babyshark_baby.mp3',
        time: 8
    },
    {
        title: '반짝반짝 작은별',
        filename: 'little_star.mp3',
        time: 15
    },
    {
        title: '사랑받기 위해 태어난 사람',
        filename: 'born_to_be_love.mp3',
        time: 14
    },
    {
        title: '마틴 루터 킹 연설',
        filename: 'martin_luther_king.mp3',
        time: 12
    },
    {
        title: '보헤미안 랩소디',
        filename: 'Bohemian_Rhapsody.mp3',
        time: 15
    },
    {
        title: '방귀소리',
        filename: 'fart_sound.mp3',
        time: 11
    },
    {
        title: '비트박스',
        filename: 'beatbox.mp3',
        time: 10
    }
];