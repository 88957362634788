export const strings = {
    appName: 'Funbit',
    steps: {
        0: '사진을 업로드하세요',
        1: '노래를 선택하거나 업로드하세요',
        2: '영상을 생성할 수 있습니다',
        3: '영상을 생성 중입니다',
        4: '영상이 생성되었습니다',
    },
    imageFrameMain: '눌러서 얼굴 사진을\n업로드 하세요',
    toLoginSignup: '로그인',
    login: '로그인',
    toSignupDesc: '아직 아이디가 없으신가요?',
    toSignupMain: '회원가입하기',
    signup: '회원가입',
    toLoginDesc: '이미 아이디가 있으신가요?',
    toLoginMain: '로그인하기',
    emailPlaceholder: '이메일',
    passwordPlaceholder: '비밀번호',
    passwordCheckPlaceholder: '비밀번호 확인',
    charge: '충전하기',
    signout: '로그아웃',
    addAudio: '음악 직접 추가하기 +',
    go: '생성하기',
    ready: '생성 준비 중',
    generating: '생성 중',
    download: '다운로드',
    failed: '생성 오류',
    time_indicate_begin: '예상 소요 시간은 ',
    time_indicate_end: '초 입니다.',
    restart: '다시하기'
};
