import { FC, HTMLAttributes } from 'react';

export const HamburgerIcon: FC<HTMLAttributes<HTMLDivElement>> = (props) => (
    <div
        {...props}
        style={{
            ...props.style,
            display: 'flex',
            flexDirection: 'column',
            height: 50,
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '18px 20px',
        }}>
        {Array.from(Array(3).keys()).map((_, ind) => (
            <div
                key={`hamburger_${ind}`}
                style={{
                    width: 18,
                    height: 1,
                    backgroundColor: 'var(--white)',
                }}
            />
        ))}
    </div>
);
